<template>
	<div class="nav">
		<div class="logo">
			<img src="../assets/nav_logo.png" class="logo" />
		</div>
		<div class="navbar_box">
			<ul class="flex_row">
				<li :class="index == 1?'ac pre':'nomal pre'" @mouseenter="move(1)" @mouseleave="leave" @click="show1">
					<span :class="index == 1?'ac pre':'nomal pre'" >
					  跑跑太空熊<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<ul class="dp_box" v-if="hide1 && index == 1" @mouseleave="hideDiaog()">
						<li :class="index2 == 1?'bt ac':'bt nomal'" @mouseenter="move2(1)" @mouseleave="leave2" @click.stop="close1(1)">品牌介绍</li>
						<li :class="index2 == 2?'bt ac':'bt nomal'" @mouseenter="move2(2)" @mouseleave="leave2" @click.stop="close1(2)">品牌热度</li>
						<li :class="index2 == 3?'bt ac':'bt nomal'" @mouseenter="move2(3)" @mouseleave="leave2" @click.stop="close1(3)">品牌优势</li>
						<li :class="index2 == 4?'bt ac':'bt nomal'" @mouseenter="move2(4)" @mouseleave="leave2" @click.stop="close1(4)">企业荣誉</li>
						<li :class="index2 == 5?'bt ac':'bt nomal'" @mouseenter="move2(5)" @mouseleave="leave2" @click.stop="close1(5)">团队介绍</li>
						<li :class="index2 == 6?'ac':'nomal'" @mouseenter="move2(6)" @mouseleave="leave2" @click.stop="close1(6)">客户体验</li>
					</ul> 
				</li>
				<li class="pre" @mouseenter="move(2)" @mouseleave="leave" @click="show2">
					<span :class="index == 2?' ac ':' nomal '" >
					  关于合作<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<ul class="dp_box" v-if="hide2 && index == 2" @mouseleave="hideDiaog2()">
						<li :class="index3 == 1?'bt ac':'bt nomal'" @mouseenter="move3(1)" @mouseleave="leave3" @click.stop="close2(1)">合作方式</li>
						<li :class="index3 == 2?'bt ac':'bt nomal'" @mouseenter="move3(2)" @mouseleave="leave3" @click.stop="close2(2)">合作地图</li>
						<li :class="index3 == 3?' ac':' nomal'" @mouseenter="move3(3)" @mouseleave="leave3" @click.stop="close2(3)">合作品牌</li>
					</ul> 
				</li>
				<li :class="index == 3?'ac':'nomal'" @mouseenter="move(3)" @mouseleave="leave" @click="goSur">品牌周边</li>
				<li :class="index == 4?'ac':'nomal'" @mouseenter="move(4)" @mouseleave="leave" @click="goNews">相关资讯</li>
				<li :class="index == 5?'ac':'nomal'" @mouseenter="move(5)" @mouseleave="leave">联系我们</li>
				<li :class="index == 6?'ac pre':'nomal pre'" @mouseenter="move(6)" @mouseleave="leave6" @click="goHead">
					HOME
					<ul class="dp_box" v-if="hide3">
						<li class="ac">马尼亚科技</li>
					</ul>
				</li>
				<li :class="index == 7?'ac':'nomal'" @mouseenter="move(7)" @mouseleave="leave" @click="goVideo">教学视频</li>
			</ul>
		</div>
		<div class="lan">
			<div :class="lan == 1?'ac':'nomal'" @click="change(1)">简</div>
			<div>|</div>
			<div :class="lan == 2?'ac':'nomal'" @click="change(2)">繁</div>
			<div>|</div>
			<div :class="lan == 3?'ac':'nomal'" @click="change(3)">EN</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'navbar',
		data() {
			return {
				lan: 1,
				index: 0,
				index2: 1,
				index3: 1,
				hide1: false,
				hide2: false,
				hide3: false
			}
		},
		methods:{
			change(i){
				this.lan = i
			},
			move(i){
				this.index = i
				if(i == 6){
					this.hide3 = true
				}else if( i == 1){
					this.hide1 = true
				}else if( i == 2){
					this.hide2 = true
				}
			},

			leave(){
				// this.index = 0
			},
			cs(){
				this.hide1 = true
			},
			move2(i){
				this.index2 = i
				this.hide1 = true
			},
			end(){
				this.hide1 = false
				// this.index = 0
			},
			end2(){
				this.hide2 = false
				// this.index = 0
			},
			leave2(){
				let _this = this
				this.index2 = 0
				// this.hide1 = false
				// setTimeout(function(){
				// 	_this.end()
				// },2000)
			},
			move3(i){
				this.index3 = i
			},
			leave3(){
				let _this = this
				this.index3 = 0
				// setTimeout(function(){
				// 	_this.end2()
				// },2000)
			},
			hideDiaog(){
				let _this = this

				this.index3 = 0
				this.end()
				// setTimeout(function(){
				// 	_this.end()
				// },2000)
			},
			hideDiaog2(){
				let _this = this
				this.index3 = 0
				this.end2()
				// setTimeout(function(){
				// 	_this.end()
				// },2000)
			},
			show1(){
				this.hide1 = !this.hide1
				this.index = 1
				this.$router.push('/')
			},
			show2(){			
				this.hide2 = !this.hide2
				this.index = 2
				this.$router.push('/cooperation')
			},
			leave6(){
				this.index = 0;
				this.hide3 = false
			},
			goHead(){
				this.hide = true
				this.$router.push('/headquarters')
			},
			goVideo(){
				this.hide = true
				this.$router.push('/teaching')
			},
			goSur(){
				this.$router.push('/surrounding')
			},
			goNews(){
				this.$router.push('/news')
			},
			close1(i){
				this.end()
				console.log(i)
				this.index2 = i
				this.$router.push('/')
				document.getElementById(i).scrollIntoView({
					behavior: "smooth",  // 平滑过渡
					block:    "start"  // 上边框与视窗顶部平齐。默认值
				});
			},
			close2(i){
				this.end2()
				this.index3 = i
				this.$router.push('/cooperation')
				document.getElementById(i).scrollIntoView({
					behavior: "smooth",  // 平滑过渡
					block:    "start"  // 上边框与视窗顶部平齐。默认值
				});
			}
		},
		mounted(){
			console.log(this.$route)
		}
	}
</script>

<style scoped="scoped">
	.nav{
		width: 100%;
		height: 80px;
		background-color: #371C72;
		box-sizing: border-box;
		padding: 20px 120px 20px 170px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lan{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		
	}
	.lan div{
		margin: 0 3px;
	}
	.nomal{
		font-size: 18px;
		color: #fff;
		outline: none;
	}
	.ac{
		color: #ffb715 !important;
		font-size: 18px;
		outline: none;
	}
	.flex_row{
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
 	.navbar_box{
		flex: 1;
	} 
	.pre{
		position: relative;
	}
	.hide_nav{
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		background-color: gray;
		opacity: 0.4;
	}
	.hide_ul{
		box-sizing: border-box;
		padding: 0;
	}
	.dp_box{
		position: absolute;
		/* top: 0; */
		left: 0;
		width: 100%;
		background-color: rgba(0,0,0,0.4);
		color: #fff;
		box-sizing: border-box;
		padding: 5px 10px;
		line-height: 40px;
		z-index: 99;
	}
	.bt{
		border-bottom: #FFFFFF solid 1px;
	}
	.flex_row>li{
		width: 130px;
	}
</style>
