<template>
	<div class="foot">
		<div class="content">
			<div class="con_box">
				<div class="con_left">
					<div class="row1">
						<div class="li">联系方式</div>
						<div class="li">其他问题</div>
						<div class="li">加入我们</div>
					</div>
					<div class="row1">
						<div class="li fz16">张经理：13538252032</div>
						<div class="li fz16">常见问题解答</div>
						<div class="li fz16">查看招聘信息</div>
					</div>
					<div class="row1">
						<div class="li fz16">汪经理：15300710021</div>
						<div class="li fz16">问题反馈</div>
						<div class="li"></div>
					</div>
					<div class="row2">
						上海总部：上海市华江路850弄75号/深圳研发部：深圳市海天一路/深圳运营中心：深圳市南山区卓越前海壹号
					</div>
				</div>
				<div class="con_right">
					<div class="pic_box">
						<img src="../assets/ft_p1.png" class="ft_pic" />
						<div>公众号二维码</div>
					</div>
					<div class="pic_box">
						<img src="../assets/ft_p2.png" class="ft_pic" />
						<div>小程序二维码</div>
					</div>
				</div>
			</div>
			<div class="ft_row">
				<div>Copyright &copy; 2020上海马尼亚娱乐咨询管理有限公司</div>
				<div>沪ICP备2020031750号  沪公网安备31011402008950号</div>
			</div>
		</div>		
	</div>
</template>

<script>
	export default {
		name: 'foot',
	}
</script>

<style scoped="scoped">
	.foot{
		width: 100%;
		height: 280px;
		background-color: #e5e5e5;
	}
	.content{
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 50px 0 ;
	}
	.con_box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: gray solid 1px;
	}
	.con_left{
		width: 900px;
	}
	.row1{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.li{
		text-align: left;
		width: 300px;
		font-size: 18px;
		line-height: 29px;
		color: #666;
	}
	.row2{
		text-align: left;
		margin-top: 15px;
		color: #666;
		font-size: 16px;
	}
	.con_right{
		width: 250px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.ft_row{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		font-size: 16px;
		color: #666;
	}
	.fz16{
		font-size: 16px;
	}
	.pic_box div{
		font-size: 16px;
		color: #999;
	}
</style>
