<template>
  <div id="app">
		<navbar></navbar>
    <router-view/>
		<foot></foot>
  </div>
</template>

<script>
	import navbar from './components/navbar.vue';
	import foot from './components/foot.vue';
	export default {
	  components: {
	    navbar,
			foot
	  }
	}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
	margin: 0;
}
body{
	margin: 0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
a{
	text-decoration: none;
	color: #fff !important;
}
a:hover,a:focus{
	text-decoration: none !important;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
ol,ul,li{
	list-style: none;
	margin: 0 !important;
}
</style>
