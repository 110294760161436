import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/headquarters',
    name: 'headquarters',
    component: () => import(/* webpackChunkName: "about" */ '../views/headquarters.vue')
  },
	{
	  path: '/cooperation',
	  name: 'cooperation',
	  component: () => import(/* webpackChunkName: "about" */ '../views/cooperation.vue')
	},
	{
	  path: '/surrounding',
	  name: 'surrounding',
	  component: () => import(/* webpackChunkName: "about" */ '../views/surrounding.vue')
	},
	{
	  path: '/teaching',
	  name: 'teaching',
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching.vue')
	},
	{
	  path: '/news',
	  name: 'news',
	  component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
	}
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
	// mode: 'hash',
  routes
})

export default router
