<template>
  <div class="index">
		<div class="banner">
			<img src="../assets/banner1.jpg" class="banner_p" />
		</div>
		<div class="content">
			<img src="../assets/bg1.jpg" class="bg" />
			<div class="box">
				<div class="box_content">
					<img src="../assets/hdq_icon.png" class="itr_icon" />
					<div class="main_title" :id="1">
						品牌介绍
					</div>
					<div class="main_title_en">
						BRAND INTRODUCTION
					</div>
					<div class="sys">
						跑跑太空熊是上海马尼亚娱乐咨询管理有限公司2020年推出儿童共享童车品牌。 延承多年从事儿童游乐，玩具行业始终坚持对产品严谨认真的态度。加入企业文化传承，结合多年来运用管理体系。 致力于打造当下优质的物联网儿童游艺产品。
					</div>
					<div class="flex_row1">
						<div class="appeal">
							<img src="../assets/index/1.png" class="white" />
							<div class="app_content">
								<div class="app_title">
									品牌创想
								</div>
								<div class="app_title_en">
									BRAND APPEAL
								</div>
								<p class="app_sys">1.跑跑太空熊 是多元化集团借由一个品牌搭建与投资者、消费者之间的桥梁。让全新的无人共享童车进入各个幸福美满家庭的生活中。 身为行业的领导者，我们清楚舞台对品牌的重要性。</p>
								<p class="app_sys">2.我们相信快乐的体验，安全卫生的产品要从行业的领导者发起，所以创造三赢的循环就是集团的目标。让新兴的品牌成长，消费者更有意愿体验的产品，帮助更加有实力的投资者扩张市场，彼此串联来相互提升。</p>
							</div>
						</div>
						<div class="innovation">
							<img src="../assets/index/2.png" class="white" />
							<div class="app_content">
								<div class="app_title">
									品牌理念
								</div>
								<div class="app_title_en">
									BRAND INNOVATION
								</div>
								<p class="app_sys">1.不需要专程去大型儿童乐园去租赁童车；</p>
								<p class="app_sys">2.不需要特地从家中劳累地搬上搬下童车；</p>
								<p class="app_sys">3.不需要花费高额的费用来购买大型童车。</p>
								<p class="app_sys">4.扫码-借车-还车，一步到位。</p>
								<p class="app_sys">5.因为跑跑太空熊·共享童车能让，幸福美满的家庭与顶级童车触手可及。</p>
							</div>
						</div>
					</div>
					<div class="flex_row2">
						<div class="image">
							<img src="../assets/index/3.png" class="white" />
							<div class="app_content" style="padding: 60px 80px;">
								<div class="app_title">
									产品形象
								</div>
								<div class="app_title_en">
									PRODUCT IMAGE
								</div>
								<div class="image_box">
									<div class="i_left">
										<p class="app_sys">1.产品形象设计主调采用日系动漫元素。</p>
										<p class="app_sys">2.引用进口不锈钢拥有极强的抗老化条件，并且结合当下潮流的亮眼明色彩搭配设计，打造全新的视觉概念，用以表达方便、 快速、以产品传递全新的共享童车的理念。</p>
									</div>
									<div class="i_right">
										<img src="../assets/bear.png" class="bear" />
									</div>									
								</div>
							</div>
						</div>
					</div>
					<img src="../assets/hdq_icon.png" class="icon" />
					<div class="flex_row1">
						<div class="advantage">
							<img src="../assets/index/4.png" class="white" />
							<div class="adv_content">
								<img src="../assets/icon_title.png" class="icon_title" />
								<div class="adv_title" :id="3">
									品牌优势
								</div>
								<div class="adv_title_en">
									BRAND ADVANTAGE
								</div>
								<p style="margin-top: 10px;">我们有六大优势</p>
								<div class="adv_row">
									<img src="../assets/at_icon1.png" class="at_icon" />
									<img src="../assets/at_icon2.png" class="at_icon" />
									<img src="../assets/at_icon3.png" class="at_icon" />
								</div>
								<div class="adv_row">
									<img src="../assets/at_icon4.png" class="at_icon" />
									<img src="../assets/at_icon5.png" class="at_icon" />
									<img src="../assets/at_icon6.png" class="at_icon" />
								</div>
							</div>
						</div>
						<div class="honor">
							<img src="../assets/index/5.png" class="white" />
							<div class="adv_content">
								<img src="../assets/icon_title.png" class="icon_title" />
								<div class="adv_title" :id="4">
									企业荣誉
								</div>
								<div class="adv_title_en">
									ENTERPRISE HONOR
								</div>
								<p style="margin-top: 10px;">顶级联盟-跑跑太空熊·智能共享童车所使用的童车均为迈凯伦、奔驰、玛莎拉蒂、兰博基尼、帕加尼等众多顶级正版授权车辆，保证产品正版授权的同时又提供了安全稳定性。真正以奢华享受，亲民价格取胜。</p>
								<div class="el_honor">
									<el-carousel :interval="4000" type="card" height="230px" style="overflow: hidden;">
									    <el-carousel-item >
									      <img src="../assets/honor1.png" class="honor_pic"/>
									    </el-carousel-item>
											<el-carousel-item >
											  <img src="../assets/honor2.png" class="honor_pic"/>
											</el-carousel-item>
											<el-carousel-item >
											  <img src="../assets/honor3.png" class="honor_pic"/>
											</el-carousel-item>
											<el-carousel-item >
											  <img src="../assets/honor4.png" class="honor_pic"/>
											</el-carousel-item>
											<el-carousel-item >
											  <img src="../assets/honor5.png" class="honor_pic"/>
											</el-carousel-item>
									  </el-carousel>
								</div>
							</div>
						</div>
					</div>
					<div class="flex_row1 mt-10" style="align-items: end;">
						<div class="left">
							<div class="intro">
								<img src="../assets/index/6.png" class="white" />
								<div class="adv_content">
									<img src="../assets/icon_title.png" class="icon_title" />
									<div class="adv_title" :id="5">
										团队介绍
									</div>
									<div class="adv_title_en">
										TEAM INTRODUCTION
									</div>
									<p class="app_sys">我们有专业的线上推广运营团队。20多位世界500强背景程序员以及来自巨头企业、985、211、双一流高校的专业人才，帮助你做市场分析，制作最优推广方案。由品牌洽谈，童车定位到最后的设计，制造，再到改装，升级，全部一条龙服务。覆盖产业链上中下游，为城市合伙人的加入提供强有力的保障。</p>
									<div class="team_bg">
										<img src="../assets/intro_bg.png" class="team_bg_pic"/>
										<div class="team_pic">
											<img src="../assets/team.jpg" class="team_pic_img">
										</div>
									</div>
								</div>
							</div>
							<div class="presentation">
								<img src="../assets/index/8.png" class="white" />
								<div class="adv_content">
									<img src="../assets/icon_title.png" class="icon_title" />
									<div class="adv_title" :id="2">
										品牌热度
									</div>
									<div class="adv_title_en">
										CASE PRESENTATION
									</div>
									<p class="app_sys" style="text-align: center;">无人租赁·溜娃高手·分享快乐</p>
									<div class="hot_pic">
										<Swiper>
										  <Slide>
												<img src="../assets/hot1.png" class="hot_img"/>
										  </Slide>
											<Slide>
												<img src="../assets/hot1.png" class="hot_img"/>
											</Slide>
											<Slide>
												<img src="../assets/hot1.png" class="hot_img"/>
											</Slide>
											<Slide>
												<img src="../assets/hot1.png" class="hot_img"/>
											</Slide>
										</Swiper>
									</div>
								</div>
							</div>
						</div>
						<div class="popularity">
							<img src="../assets/index/7.png" class="white" />
							<div class="adv_content">
								<img src="../assets/icon_title.png" class="icon_title" />
								<div class="adv_title" :id="6">
									客户体验
								</div>
								<div class="adv_title_en">
									BRAND POPULARITY
								</div>
								<p class="app_sys" style="text-align: center;">无人租赁·溜娃高手·分享快乐</p>
								<div class="pop_box">
									<img src="../assets/pop_bg.png" class="pop_bg_pic"/>
									<div class="pop_pic">
										<Swiper>
										  <Slide>
												<img src="../assets/pop1.png" class="pop_pic_img"/>
										  </Slide>
											<Slide>
												<img src="../assets/pop2.jpg" class="pop_pic_img"/>
											</Slide>
											<Slide>
												<img src="../assets/pop3.jpg" class="pop_pic_img"/>
											</Slide>
											<Slide>
												<img src="../assets/pop4.jpg" class="pop_pic_img"/>
											</Slide>
										</Swiper>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, Slide } from 'vue-swiper-component';

export default {
  name: 'index',
  components: {
		Swiper,
		Slide
  }
}
</script>

<style scoped="scoped">
	.index{
		margin: 0;
		padding: 0;
	}
	.banner{
		width: 100%;
		height: 600px;
	}
	.banner_p{
		width: 100%;
		height: 600px;
	}
	.content{
		width: 100%;
		position: relative;
	}
	.bg{
		width: 100%;
	}
	.box{
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-600px);
		width: 1200px;
		box-sizing: border-box;
		padding: 50px 0;
	}
	.box_content{
		width: 100%;
		position: relative;
	}
	.main_title{
		font-size: 52px;
		color: #fff95a;
		font-weight: bold;
	}
	.main_title_en{
		color: #d3e5ff;
		font-size: 30px;
		position: relative;
	}
	.main_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		margin: 0 auto;
		margin-top: 5px;
		background-color: #d3e5ff;	
	}
	.sys{
		text-align: left;
		font-size: 26px;
		color: #fff;
		margin-top: 5px;
	}
	.itr_icon{
		position: absolute;
		width: 100px;
		top: 0px;
		left: 380px;
	}
	.flex_row1{
		/* margin-top: 55px; */
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		box-sizing: border-box;
		padding: 20px;
	}
	.appeal{
		width: 40%;
		position: relative;
	}
	.innovation{
		width: 40%;
		position: relative;
		/* height: 600px; */
	}
	.white{
		width: 100%;
	}
	.image{
		width: 80%;
		/* height: 570px; */
		margin: 0 auto;
		margin-top: 50px;
		position: relative;
	}
	.icon{
		width: 100px;
		margin-top: 20px;
	}
	.advantage{
		width: 40%;
		position: relative;
		/* height: 550px; */
	}
	.honor{
		width: 40%;
		position: relative;
		overflow: hidden;
		/* height: 540px; */
	}
	.left{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 40%;
	}
	.intro{
		width: 100%;
		position: relative;
		/* height: 790px; */
	}
	.presentation{
		width: 100%;
		position: relative;
		margin-top: 20px;
		/* height: 640px; */
	}
	.popularity{
		width: 40%;
		position: relative;
		/* height: 1160px; */
	}
	.mt-10{
		margin-top: -30px;
	}
	.app_content{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 60px;
	}
	.app_title{
		font-size: 30px;
		color: #333;
		text-align: left;
	}
	.app_title_en{
		font-size: 24px;
		color: #999;
		text-align: left;
	}
	.app_title_en::after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		background-color: #999;
		margin-top: 10px;
	}
	.app_sys{
		text-align: left;
		margin-top: 10px;
		color: #333;
		font-size: 20px;
	}
	.image_box{
		display: flex;
		justify-content: flex-start;
	}
	.i_left{
		width: 40%;
	}
	.i_right{
		width: 50%;
	}
	.bear{
		width: 240px;
		transform: translateY(-40px);
	}
	.adv_content{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 40px;
	}
	.adv_title{
		font-weight: bold;
		font-size: 42px;
		color: #5454b0;
	}
	.adv_title_en{
		font-size: 24px;
		color: #999;
	}
	.adv_title_en::after{
		content: '';
		display: block;
		width: 20px;
		height: 1px;
		background-color: #333;
		margin: 0 auto;
		margin-top: 5px;
	}
	.adv_row{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 10px;
	}
	.at_icon{
		width: 90px;
	}
	.icon_title{
		position: absolute;
		width: 50px;
		top: 40px;
		left: 90px;
	}
	.honor_pic{
		width: 80%;
	}
	.el_honor{
		overflow-y: hidden  !important;
	}
	.team_bg{
		width: 100%;
		position: relative;
		margin-top: 10px;
	}
	.team_bg_pic{
		width: 100%;
	}
	.team_pic_img{
		position: absolute;
		top: 2%;
		left: 2%;
		width: 96%;
		height: 96%;
		border-radius: 20px;
	}
	.hot_pic{
		width: 100%;
		border-radius: 20px;
	}
	.hot_img{
		width: 100%;
		border-radius: 20px;
	}
	.pop_box{
		margin-top: 30px;
		position: relative;
	}
	.pop_bg_pic{
		width: 100%;
	}
	.pop_pic{
		position: absolute;
		top: 2%;
		left: 2%;
		width: 96%;
	}
	.pop_pic_img{
		width: 100%;
	}
</style>